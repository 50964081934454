export const state = () => ({
  basePath: '/user-management/featured-agents',
  loading: false,
  permissions: [],
  featuredAgents: [],
  meta: {},
});
export const getters = {};
export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_FEATURED_AGENTS(state, payload) {
    state.featuredAgents = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  RESET_DEFAULT(state) {
    state.permissions = [];
    state.featuredAgents = [];
    state.meta = {};
    state.isRefundAllowed = null;
  },
};

import { generateParams } from '@/utils/helpers';

export const actions = {
  async getInitData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/featured_agents/get_init_data', {
        params: params,
      });

      let result = data.data.data;
      commit('SET_PERMISSIONS', result.permissions.data);
      commit('SET_FEATURED_AGENTS', result.featured_agents.data);
      commit('SET_META', result.featured_agents.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getData({ commit, rootState }, query) {
    try {
      commit('SET_LOADING', true);
      const params = generateParams(rootState, query);
      const data = await this._vm.$http.get('/api/featured_agents/get_data', {
        params: params,
      });

      let result = data.data;
      commit('SET_FEATURED_AGENTS', result.data);
      commit('SET_META', result.meta);
      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
