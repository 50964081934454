import Vue from 'vue';
import Router from 'vue-router';
import MAuth from '@/middleware/auth';
import MAllowedPage from '@/middleware/allowedPage';
import WebInit from '@/middleware/webInit';

Vue.use(Router);
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`);
}
import middlewarePipeline from '../router/middlewarePipeline';

const routes = [
  // Dashboard
  {
    name: 'Dashboard',
    path: '',
    component: loadView('index'),
    meta: {
      middleware: [MAuth],
    },
  },
  // Pages
  {
    name: 'Daftar Proyek',
    path: '/project-management/projects',
    component: loadView('project-management/projects/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Proyek',
    path: '/project-management/projects/add',
    component: loadView('project-management/projects/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Proyek',
    path: '/project-management/projects/edit/:uuid',
    component: loadView('project-management/projects/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Proyek',
    path: '/project-management/projects/:uuid',
    component: loadView('project-management/projects/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Tipe Unit',
    path: '/project-management/unit-types/add',
    component: loadView('project-management/unit-types/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Tipe Unit',
    path: '/project-management/unit-types/edit/:uuid',
    component: loadView('project-management/unit-types/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Tipe Unit',
    path: '/project-management/unit-types/:uuid',
    component: loadView('project-management/unit-types/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Kategori Unit',
    path: '/project-management/unit-categories/add',
    component: loadView('project-management/unit-categories/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Kategori Unit',
    path: '/project-management/unit-categories/edit/:id',
    component: loadView('project-management/unit-categories/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Kategori Unit',
    path: '/project-management/unit-categories/:id',
    component: loadView('project-management/unit-categories/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Unit',
    path: '/project-management/units/add',
    component: loadView('project-management/units/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Unit',
    path: '/project-management/units/edit/:uuid',
    component: loadView('project-management/units/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Registrasi Mitra Agen',
    path: '/registration-management/agents',
    component: loadView('registration-management/agents/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Registrasi Mitra Agen',
    path: '/registration-management/agents/:id',
    component: loadView('registration-management/agents/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Registrasi Mitra Proyek',
    path: '/registration-management/projects',
    component: loadView('registration-management/projects/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Registrasi Mitra Proyek',
    path: '/registration-management/projects/:id',
    component: loadView('registration-management/projects/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Bantu Cari Hunian',
    path: '/project-inquiries',
    component: loadView('project-inquiries/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Bantu Cari Hunian',
    path: '/project-inquiries/:id',
    component: loadView('project-inquiries/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Verifikasi Agen',
    path: '/registration-management/agent-verifications',
    component: loadView('registration-management/agent-verifications/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Verifikasi Agen',
    path: '/registration-management/agent-verifications/:id',
    component: loadView('registration-management/agent-verifications/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Kode Referral',
    path: '/registration-management/referral-codes',
    component: loadView('registration-management/referral-codes/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Kode Referral',
    path: '/registration-management/referral-codes/:uuid',
    component: loadView('registration-management/referral-codes/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Program Referral',
    path: '/registration-management/referral-programs',
    component: loadView('registration-management/referral-programs/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Program Referral',
    path: '/registration-management/referral-programs/add',
    component: loadView('registration-management/referral-programs/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Program Referral',
    path: '/registration-management/referral-programs/edit/:uuid',
    component: loadView('registration-management/referral-programs/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Program Referral',
    path: '/registration-management/referral-programs/:uuid',
    component: loadView('registration-management/referral-programs/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Leads',
    path: '/transaction-management/leads',
    component: loadView('transaction-management/leads/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Leads',
    path: '/transaction-management/leads/add',
    component: loadView('transaction-management/leads/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Leads',
    path: '/transaction-management/leads/edit/:uuid',
    component: loadView('transaction-management/leads/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Leads',
    path: '/transaction-management/leads/:uuid',
    component: loadView('transaction-management/leads/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Klien',
    path: '/transaction-management/clients',
    component: loadView('transaction-management/clients/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Klien',
    path: '/transaction-management/clients/:uuid',
    component: loadView('transaction-management/clients/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Transaksi',
    path: '/transaction-management/transactions',
    component: loadView('transaction-management/transactions/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Transaksi',
    path: '/transaction-management/transactions/:uuid',
    component: loadView('transaction-management/transactions/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Billing',
    path: '/transaction-management/billings/:uuid',
    component: loadView('transaction-management/billings/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  // {
  //   name: 'Daftar Tipe Akun',
  //   path: '/user-management/account-types',
  //   component: loadView('user-management/account-types/index'),
  //   meta: {
  //     middleware: [MAuth, MAllowedPage],
  //   },
  // },
  // {
  //   name: 'Daftar User Group',
  //   path: '/user-management/user-groups',
  //   component: loadView('user-management/user-groups/index'),
  //   meta: {
  //     middleware: [MAuth, MAllowedPage],
  //   },
  // },
  {
    name: 'Daftar Admin Beliruma.co.id',
    path: '/user-management/admin-users',
    component: loadView('user-management/admin-users/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Agen',
    path: '/user-management/beliruma-users',
    component: loadView('user-management/beliruma-users/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Agen',
    path: '/user-management/beliruma-users/add',
    component: loadView('user-management/beliruma-users/add'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Agen',
    path: '/user-management/beliruma-users/:uuid',
    component: loadView('user-management/beliruma-users/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Agen Member',
    path: '/user-management/agent-members',
    component: loadView('user-management/agent-members/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Agen Member',
    path: '/user-management/agent-members/:uuid',
    component: loadView('user-management/agent-members/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },

  {
    name: 'Daftar Penarikan Komisi',
    path: '/commission-management/withdrawal-requests',
    component: loadView('commission-management/withdrawal-requests/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },

  {
    name: 'Detail Penarikan Komisi',
    path: '/commission-management/withdrawal-requests/:id',
    component: loadView('commission-management/withdrawal-requests/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'User Profile',
    path: '/user-profile',
    component: loadView('user-profile/index'),
    meta: {
      middleware: [MAuth],
    },
  },
  {
    name: 'Daftar Banner',
    path: '/banner-management/banners',
    component: loadView('banner-management/banners/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Banner',
    path: '/banner-management/banners/add',
    component: loadView('banner-management/banners/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Banner',
    path: '/banner-management/banners/edit/:uuid',
    component: loadView('banner-management/banners/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'login',
    path: '/login',
    component: loadView('login'),
    meta: {
      middleware: [],
    },
  },
  {
    name: 'Daftar Pengajuan KPR',
    path: '/mortgage-requests',
    component: loadView('mortgage-requests/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Pengajuan KPR',
    path: '/mortgage-requests/:id',
    component: loadView('mortgage-requests/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Listing',
    path: '/data-entry/listings/add',
    component: loadView('data-entry/listings/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Listing',
    path: '/data-entry/listings/edit/:uuid',
    component: loadView('data-entry/listings/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Listing',
    path: '/data-entry/listings/:uuid',
    component: loadView('data-entry/listings/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Promo',
    path: '/promo-management/promos',
    component: loadView('promo-management/promos/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Promo',
    path: '/promo-management/promos/add',
    component: loadView('promo-management/promos/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Promo',
    path: '/promo-management/promos/edit/:uuid',
    component: loadView('promo-management/promos/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Transaksi Kode Promo',
    path: '/promo-management/promo-orders',
    component: loadView('promo-management/promo-orders/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Agensi',
    path: '/master-data/agencies/add',
    component: loadView('master-data/agencies/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Agensi',
    path: '/master-data/agencies/edit/:id',
    component: loadView('master-data/agencies/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Agensi',
    path: '/master-data/agencies/:id',
    component: loadView('master-data/agencies/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Agensi',
    path: '/master-data/agencies',
    component: loadView('master-data/agencies/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Kantor Agen',
    path: '/master-data/agent-offices/add',
    component: loadView('master-data/agent-offices/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Kantor Agen',
    path: '/master-data/agent-offices/edit/:id',
    component: loadView('master-data/agent-offices/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Kantor Agen',
    path: '/master-data/agent-offices/:id',
    component: loadView('master-data/agent-offices/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Kantor Agen',
    path: '/master-data/agent-offices',
    component: loadView('master-data/agent-offices/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Tambah Area',
    path: '/master-data/areas/add',
    component: loadView('master-data/areas/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Ubah Area',
    path: '/master-data/areas/edit/:id',
    component: loadView('master-data/areas/edit/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Area',
    path: '/master-data/areas',
    component: loadView('master-data/areas/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Pembelian Agen',
    path: '/transaction-management/orders',
    component: loadView('transaction-management/orders/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Penggunaan Koin',
    path: '/transaction-management/coin-usages',
    component: loadView('transaction-management/coin-usages/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Refund Request Initiator',
    path: '/refund-management-initiator/refunds',
    component: loadView('refund-management/initiator/refunds/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Buat Refund Request Initiator',
    path: '/refund-management-initiator/refunds/add/:uuid',
    component: loadView('refund-management/initiator/refunds/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Buat Refund Request Initiator',
    path: '/refund-management-initiator/refunds/add',
    component: loadView('refund-management/initiator/refunds/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Refund Request Initiator',
    path: '/refund-management-initiator/refunds/:uuid',
    component: loadView('refund-management/initiator/refunds/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Refund Request Manager',
    path: '/refund-management-manager/refunds',
    component: loadView('refund-management/manager/refunds/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Buat Refund Request Manager',
    path: '/refund-management-manager/refunds/add/:uuid',
    component: loadView('refund-management/manager/refunds/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Buat Refund Request Manager',
    path: '/refund-management-manager/refunds/add',
    component: loadView('refund-management/manager/refunds/add/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Detail Refund Request Manager',
    path: '/refund-management-manager/refunds/:uuid',
    component: loadView('refund-management/manager/refunds/detail'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  {
    name: 'Daftar Penggunaan Kode Referral',
    path: '/referral-management/referrals',
    component: loadView('referral-management/referrals/index'),
    meta: {
      middleware: [MAuth, MAllowedPage],
    },
  },
  { path: '*', component: loadView('404') },
];

export default (store) => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return {
          selector: to.hash,
        };
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  router.beforeEach((to, from, next) => {
    console.log('ada meta nih', to);

    if (!to.meta.middleware) {
      return next();
    }
    // const middleware = to.meta.middleware;
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = {
      to,
      from,
      next,
      store,
      router,
    };
    middleware.unshift(WebInit);
    const nextMiddleware = middlewarePipeline(context, middleware, 1);
    console.log(middleware[0]);
    return middleware[0]({ ...context, next: nextMiddleware });
  });
  return router;
};
// export default router;
